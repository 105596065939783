import {FC, Fragment, useCallback, useEffect, useRef, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import styled from 'styled-components';

import {
  EmailString,
  FrontendUserAuthMethodType,
  HoobiizWhiteLabelingLoginType,
} from '@shared/dynamo_model';
import {useTheme} from '@shared/frontends/theme_context';
import {useSsrContext} from '@shared/frontends/use_ssr_context';
import {asMap, asString, neverHappens} from '@shared/lib/type_utils';
import {MfaCode} from '@shared/model/auth/user';

import {sharedApiCall} from '@shared-frontend/api';
import {LoginTheme1} from '@shared-frontend/components/auth/login_theme_1';
import {Button, NavLink, UnthemedNavLink} from '@shared-frontend/components/core/button';
import {Input} from '@shared-frontend/components/core/input_v2';
import {Spacing} from '@shared-frontend/components/core/spacing';
import {notifyError} from '@shared-frontend/lib/notification';
import {EmptyFragment, NULL_REF} from '@shared-frontend/lib/react';
import {setSession, useSession} from '@shared-frontend/lib/session_store';
import {optionalPx} from '@shared-frontend/lib/styled_utils';

type LoginFormMode = 'email-form' | 'email-password-form' | 'wait-for-magic-link' | 'mfa-form';

export const Login: FC<{disabled?: boolean}> = ({disabled}) => {
  const session = useSession();
  const preventAutoNav = useRef(false);
  const mfaCodeRef = useRef<HTMLInputElement>(NULL_REF);
  const navigate = useNavigate();
  const {state: locationState} = useLocation();
  const {
    main: {api, textColor, logo},
    auth: {postLoginPage, userAuthTypes},
  } = useTheme();
  const whiteLabeling = useSsrContext().whiteLabeling?.login;

  const [email, setEmail] = useState<string | undefined>();
  const [password, setPassword] = useState<string | undefined>();
  const [mfaCode, setMfaCode] = useState<MfaCode | undefined>();
  const [formMode, setFormMode] = useState<LoginFormMode>(
    userAuthTypes.length === 1 && userAuthTypes[0] === FrontendUserAuthMethodType.Password
      ? 'email-password-form'
      : 'email-form'
  );

  useEffect(() => {
    if (!disabled && session !== undefined && !preventAutoNav.current) {
      navigate(postLoginPage, {replace: true});
    }
  }, [disabled, navigate, postLoginPage, session]);

  useEffect(() => {
    if (formMode === 'mfa-form') {
      mfaCodeRef.current?.focus();
    }
  }, [formMode]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleEmailSubmit = useCallback(() => {
    setIsSubmitting(true);
    sharedApiCall(api, '/request-login', {email: email as EmailString})
      .then(data => {
        setIsSubmitting(false);
        if (data.authType === FrontendUserAuthMethodType.MagicLink) {
          setFormMode('wait-for-magic-link');
        } else if (data.authType === FrontendUserAuthMethodType.MfaCode) {
          setFormMode('mfa-form');
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        } else if (data.authType === FrontendUserAuthMethodType.Password) {
          setFormMode('email-password-form');
        } else {
          neverHappens(data.authType);
        }
      })
      .catch(err => {
        setIsSubmitting(false);
        notifyError(err);
      });
  }, [api, email]);

  const handleLoginSubmit = useCallback(() => {
    const authType =
      formMode === 'mfa-form'
        ? FrontendUserAuthMethodType.MfaCode
        : formMode === 'email-password-form'
          ? FrontendUserAuthMethodType.Password
          : undefined;
    if (authType === undefined) {
      notifyError(`Mode d'autentification invalide`);
      return;
    }
    setIsSubmitting(true);
    sharedApiCall(api, '/login', {
      email: email as EmailString,
      authType,
      password,
      mfaCode,
    })
      .then(data => {
        setIsSubmitting(false);
        if (data.success) {
          preventAutoNav.current = true;
          setSession(data.session);
          const from = asString(asMap(locationState, {}).from);
          if (from === undefined) {
            navigate(postLoginPage, {replace: true});
          } else {
            navigate(from, {replace: true});
          }
        } else {
          notifyError('Échec de la connexion');
        }
      })
      .catch(err => {
        setIsSubmitting(false);
        notifyError(err);
      });
  }, [formMode, api, email, password, mfaCode, locationState, navigate, postLoginPage]);

  let formContent = EmptyFragment;

  if (formMode === 'email-form' || formMode === 'email-password-form' || formMode === 'mfa-form') {
    formContent = (
      <Fragment>
        <Title>Connexion</Title>
        <Subtitle>Accédez à votre compte</Subtitle>
        <Separator $color={textColor} $top={22} $bottom={18} />
        <FormInput>
          <Input
            width="100%"
            type="email"
            value={email}
            syncState={setEmail}
            disabled={formMode === 'mfa-form'}
            placeholder="jean@exemple.com"
            label="ADRESSE EMAIL"
            autoComplete="email"
            autoFocus
          />
        </FormInput>
        <Spacing height={12} />
        <FormInput hidden={formMode !== 'email-password-form'}>
          <Input
            width="100%"
            type="password"
            value={password}
            syncState={setPassword}
            label="MOT DE PASSE"
            autoComplete="current-password"
          />
        </FormInput>
        <Spacing height={12} />
        <FormInput hidden={formMode !== 'mfa-form'}>
          <Input
            ref={mfaCodeRef}
            width="100%"
            type="number"
            value={mfaCode}
            syncState={setMfaCode}
            placeholder="000000"
            label="CODE D'AUTHENTIFICATION"
            autoComplete="one-time-code"
          />
        </FormInput>
        <Spacing height={28} />
        <LegalText>
          En continuant, vous reconnaissez avoir lu les{' '}
          <NavLink to="/terms">mentions légales</NavLink>
        </LegalText>
        <Spacing height={28} />
        <Button expand submit disabled={isSubmitting}>
          {formMode === 'email-password-form' ? 'Connexion' : 'Continuer'}
        </Button>
        <Spacing height={28} />
        <ForgotPasswordNavLink to="/forgot-password">Mot de passe oublié ?</ForgotPasswordNavLink>
      </Fragment>
    );
  }
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  else if (formMode === 'wait-for-magic-link') {
    formContent = (
      <Fragment>
        <Title>Connexion en cours...</Title>
        <Spacing height={24} />
        <Subtitle>
          Nous vous avons envoyé un email contenant un lien. Cliquez sur le lien pour vous
          connecter. Vous pouvez fermer cette page.
        </Subtitle>
      </Fragment>
    );
  } else {
    neverHappens(formMode);
  }

  const form = (
    <Form onSubmit={formMode === 'email-form' ? handleEmailSubmit : handleLoginSubmit}>
      <LogoNavLink to="/">{logo}</LogoNavLink>
      <Spacing height={24} />
      {formContent}
    </Form>
  );

  if (whiteLabeling?.type === HoobiizWhiteLabelingLoginType.Theme1) {
    return <LoginTheme1 whiteLabeling={whiteLabeling}>{form}</LoginTheme1>;
  }
  return form;
};

Login.displayName = 'Login';

const Form = styled.form`
  width: 95vw;
  max-width: 360px;
  height: 100%;
  margin: auto;
  padding: 32px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 1.5;
`;

const LogoNavLink = styled(UnthemedNavLink)`
  height: 64px;
  & > * {
    width: 100%;
    height: 100%;
  }
`;

const Title = styled.h3`
  font-weight: 700;
  font-size: 160%;
`;

const Subtitle = styled.p`
  opacity: 0.6;
  font-weight: 400;
`;

const FormInput = styled.div`
  width: 100%;
  text-align: left;
`;

const Separator = styled.div<{$color: string; $top?: number; $bottom?: number}>`
  height: 1px;
  width: 100%;
  background-color: ${p => p.$color}20;
  ${p => optionalPx('margin-top', p.$top)}
  ${p => optionalPx('margin-bottom', p.$bottom)}
`;

const LegalText = styled.div`
  width: 100%;
  text-align: left;
  font-size: 80%;
`;

const ForgotPasswordNavLink = styled(NavLink)`
  font-size: 80%;
`;
