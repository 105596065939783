import {
  DetailedHTMLProps,
  ForwardedRef,
  forwardRef,
  HTMLAttributes,
  ImgHTMLAttributes,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import styled from 'styled-components';

import {getStaticDomain} from '@shared/frontends/frontend_constant';
import {useSsrContext} from '@shared/frontends/use_ssr_context';

import {Custom} from '@shared-frontend/lib/react';
import {optional} from '@shared-frontend/lib/styled_utils';
import {useSmartMemo} from '@shared-frontend/lib/use_smart_memo';

interface ResponsiveImageData {
  srcData: ImageSrcData;
  width: ImageDimensionValue;
  /** Record<MAX_SCREEN_WIDTH, IMAGE_DIMENSION> */
  thresholds?: Record<number, ImageDimensionValue>;
}
type MultiResponsiveImageData = Record<number, ResponsiveImageData>;

export const ALL_WIDTH = 999999;

function generateSizes(
  width: ImageDimensionValue,
  thresholds: Record<number, ImageDimensionValue> = {}
): string {
  return [
    ...Object.entries(thresholds)
      .sort(([k1], [k2]) => parseFloat(k1) - parseFloat(k2))
      .map(([maxWidth, dim]) => `(max-width: ${maxWidth}px) ${dimToString(dim)}`),
    dimToString(width),
  ].join(', ');
}

export interface ImageProps {
  srcAndSizes: ResponsiveImageData | MultiResponsiveImageData;
  alt: string;
  pictureProps?: DetailedHTMLProps<HTMLAttributes<HTMLPreElement>, HTMLPreElement>;
  brightness?: number;
  cover?: boolean;
  objectPosition?: React.CSSProperties['objectPosition'];
  lazyLoading?: boolean;
  decorative?: boolean;
  rawUrls?: boolean;
  children?: ReactNode;
}
export type ImageDimensionValue = string | number;
export type ImageDimension =
  | {width: ImageDimensionValue}
  | {height: ImageDimensionValue}
  | {width: ImageDimensionValue; height: ImageDimensionValue};

export type ImageFullProps = ImageProps & ImageDimension;

export const Image: Custom<ImageFullProps, 'img'> = forwardRef((props, ref) => {
  const {
    srcAndSizes,
    alt,
    width,
    height,
    pictureProps = {},
    brightness,
    cover,
    objectPosition,
    lazyLoading,
    decorative,
    rawUrls,
    children,
    ...imgProps
  } = props;
  // const pageLoaded = usePageLoaded();
  const pageLoaded = true as boolean;
  const srcAndSizesNormalized = useSmartMemo(
    'srcData' in srcAndSizes ? {[ALL_WIDTH]: srcAndSizes} : srcAndSizes
  );

  const sortedSources = useMemo(() => {
    return Object.entries(srcAndSizesNormalized)
      .map(e => [parseFloat(e[0]), e[1]] as const)
      .sort((e1, e2) => e2[0] - e1[0]);
  }, [srcAndSizesNormalized]);

  const [imageLoadError, setImageLoadError] = useState(false);
  const handleLoadError = useCallback(() => setImageLoadError(true), []);
  const [imageLoaded, setImageLoaded] = useState(false);
  const handleLoad = useCallback(() => setImageLoaded(true), []);

  const {host} = useSsrContext();
  const staticDomain = useMemo(() => getStaticDomain(host), [host]);
  const defaultSrc = useMemo(() => {
    return Object.entries(srcAndSizesNormalized).sort(
      (e1, e2) => parseFloat(e2[0]) - parseFloat(e1[0])
    )[0]?.[1].srcData.src;
  }, [srcAndSizesNormalized]);

  const [canAddSrc, setCanAddSrc] = useState(false);

  const decorativeProps: ImgHTMLAttributes<HTMLImageElement> = useMemo(() => {
    return decorative ? {role: 'presentation', 'aria-hidden': true} : {};
  }, [decorative]);

  // When the image data change, reset the "loadError" so we try to load the new image
  useEffect(() => {
    setImageLoadError(false);
    setCanAddSrc(false);
  }, [srcAndSizesNormalized]);

  // We need to add SRC after first rendering because of a bug in Safari
  // https://github.com/facebook/react/issues/22684
  useEffect(() => {
    if (!canAddSrc) {
      setCanAddSrc(true);
    }
  }, [canAddSrc]);

  const picture = (
    <StyledPicture $width={width} $height={height} {...pictureProps}>
      {(pageLoaded && !imageLoadError ? sortedSources : []).flatMap((imageData, i) => {
        const [
          srcThreshold,
          {
            srcData: {sources},
            width,
            thresholds,
          },
        ] = imageData;
        return sources.map(source => (
          <source
            key={`${source.type}-${srcThreshold}`}
            media={`(max-width: ${srcThreshold}px) and (min-width: ${
              (sortedSources[i + 1]?.[0] ?? -1) + 1
            }px)`}
            srcSet={
              rawUrls
                ? source.srcset
                : source.srcset
                    .split(', ')
                    .map(srcSetValue => `${staticDomain}${srcSetValue}`)
                    .join(', ')
            }
            sizes={generateSizes(width, thresholds)}
            type={source.type}
            onError={handleLoadError}
          />
        ));
      })}
      <StyledImage
        ref={ref as ForwardedRef<HTMLImageElement>}
        loading={lazyLoading ? 'lazy' : undefined}
        src={
          canAddSrc
            ? imageLoadError || !pageLoaded
              ? sortedSources[0]?.[1].srcData.thumbnail
              : rawUrls
                ? defaultSrc
                : `${staticDomain}${defaultSrc}`
            : undefined
        }
        $infoMap={sortedSources.map(
          ([
            threshold,
            {
              srcData: {aspectRatio, thumbnail},
            },
          ]) => ({threshold, aspectRatio, thumbnail})
        )}
        alt={alt}
        $width={width}
        $height={height}
        $brightness={brightness}
        $cover={cover}
        $objectPosition={objectPosition}
        $loaded={imageLoaded}
        onError={handleLoadError}
        onLoad={handleLoad}
        {...decorativeProps}
        {...imgProps}
      />
    </StyledPicture>
  );

  if (children === undefined) {
    return picture;
  }
  return (
    <PictureWrapper>
      {picture}
      <Content>{children}</Content>
    </PictureWrapper>
  );
});
Image.displayName = 'Image';

const dimToString = (dim: ImageDimensionValue): string =>
  typeof dim === 'string' ? dim : `${dim}px`;

const StyledPicture = styled.picture<{
  $width?: ImageDimensionValue;
  $height?: ImageDimensionValue;
}>`
  ${p => (p.$width === undefined ? undefined : `width: ${dimToString(p.$width)}`)};
  ${p => (p.$height === undefined ? undefined : `height: ${dimToString(p.$height)}`)};
  overflow: hidden;
`;

const StyledImage = styled.img<{
  $infoMap: {
    threshold: number;
    aspectRatio: string;
    thumbnail: string;
  }[];
  $width?: ImageDimensionValue;
  $height?: ImageDimensionValue;
  $brightness?: number;
  $cover?: boolean;
  $objectPosition?: React.CSSProperties['objectPosition'];
  $loaded: boolean;
}>`
  display: block;
  ${p => (p.$width === undefined ? undefined : `width: ${dimToString(p.$width)}`)};
  ${p => (p.$height === undefined ? undefined : `height: ${dimToString(p.$height)}`)};
  ${p =>
    p.$infoMap
      .map(
        ({threshold, aspectRatio, thumbnail}, i, arr) => `
    @media (max-width: ${threshold}px) and (min-width: ${(arr[i + 1]?.threshold ?? -1) + 1}px) {
      aspect-ratio: ${aspectRatio};
      ${!p.$loaded && `background-image: url("${thumbnail}");`}
    }
  `
      )
      .join('\n')}
  ${p => (p.$brightness !== undefined ? `filter: brightness(${p.$brightness}%);` : undefined)}
  
  
  background-repeat: no-repeat;
  background-position: center center;
  ${p =>
    p.$cover
      ? `
  background-size: cover;
  object-fit: cover;`
      : `
  background-size: contain;
  object-fit: contain;
  `}
  ${p => optional('object-position', p.$objectPosition)}
`;

const PictureWrapper = styled.div`
  position: relative;
  width: max-content;
  height: max-content;
`;

const Content = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;
