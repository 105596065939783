import {createContext, JSX, useContext, useMemo} from 'react';

import {baseTheme} from '@shared/frontends/frontend_base_theme';
import {FrontendName} from '@shared/frontends/frontend_constant';
import {FrontendTheme, WhiteLabeling} from '@shared/frontends/frontend_theme_model';
import {useSsrContext} from '@shared/frontends/use_ssr_context';
import {deepMerge} from '@shared/lib/object_utils';
import {DeepPartial} from '@shared/lib/type_utils';

export const ThemeContext = createContext<FrontendTheme>({...baseTheme});
export function useTheme(): FrontendTheme {
  const ssrContext = useSsrContext();
  const baseTheme = useContext(ThemeContext);
  const theme = useMemo(
    () => applyWhiteLabeling(baseTheme, ssrContext.whiteLabeling),
    [baseTheme, ssrContext.whiteLabeling]
  );
  return theme;
}

export function createTheme(
  frontendName: FrontendName,
  overrides: DeepPartial<FrontendTheme>,
  extra: {logo: JSX.Element}
): FrontendTheme {
  const {logo} = extra;
  return deepMerge(
    deepMerge(baseTheme, {main: {frontendName}}),
    deepMerge(overrides, {main: {logo}})
  ) as FrontendTheme;
}

function applyWhiteLabeling(
  theme: FrontendTheme,
  whiteLabeling: WhiteLabeling | undefined
): FrontendTheme {
  if (!whiteLabeling) {
    return theme;
  }
  return theme;
}
