import {useMemo} from 'react';
import {styled} from 'styled-components';

import {useSsrContext} from '@shared/frontends/use_ssr_context';
import {SanitizedItem} from '@shared/model/search_tables';

import {
  AnyHoobiizMedia,
  mediaSrcData,
} from '@shared-frontend/components/auth/hoobiiz_media_view_lib';
import {Image, ImageDimension, ImageFullProps} from '@shared-frontend/components/core/image';
import {Custom} from '@shared-frontend/lib/react';

interface HoobiizMediaViewProps {
  media?: SanitizedItem<'HoobiizMedia'>;
  size?: ImageDimension;
  radius?: number;
}

export function hoobiizMediaToProps(
  host: string,
  media: AnyHoobiizMedia | undefined,
  size: ImageDimension
): ImageFullProps {
  return {
    alt: '',
    srcAndSizes: {srcData: mediaSrcData(host, media), width: 'width' in size ? size.width : '100%'},
    rawUrls: true,
    ...size,
  };
}

export const HoobiizMediaView: Custom<
  HoobiizMediaViewProps & Partial<ImageFullProps>,
  'img'
> = props => {
  const {media, size = {width: '100%', height: '100%'}, radius, className, ...extraProps} = props;
  const {host} = useSsrContext();

  const imageProps = useMemo<ImageFullProps>(
    () => hoobiizMediaToProps(host, media, size),
    [media, host, size]
  );

  return (
    <StyledImage
      {...imageProps}
      lazyLoading
      cover
      $radius={radius}
      pictureProps={{className}}
      {...extraProps}
    />
  );
};

HoobiizMediaView.displayName = 'HoobiizImageView';

const StyledImage = styled(Image)<{$radius?: number}>`
  ${p => (p.$radius === undefined ? false : `overflow: hidden; border-radius: ${p.$radius}px`)}
`;
