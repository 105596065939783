import {UserSessionItem} from '@shared/api/definitions/frontend_shared_api';
import {FrontendDataStore} from '@shared/frontends/frontend_data_store';
import {WhiteLabeling} from '@shared/frontends/frontend_theme_model';

interface WindowExtra {
  captcha?: string;
  initialSession?: UserSessionItem;
  whiteLabeling?: WhiteLabeling;
  ssr?: boolean;
  NONCE_ID?: string;
  ANALYTICS_ID?: string;
  DATA_STORE?: FrontendDataStore[keyof FrontendDataStore];
}

// Unsafe casting of window as it can be undefined during SSR.
// Use this when you are sure your code will never run during SSR.
export function getWindowUnsafe(): Window & WindowExtra {
  return window;
}

// During SSR, window is undefined
export function getWindow(): (Window & WindowExtra) | undefined {
  return globalThis.window;
}
