import {FC, ReactNode} from 'react';
import styled from 'styled-components';

import {HoobiizWhiteLabelingLoginType} from '@shared/dynamo_model';
import {WhiteLabeling} from '@shared/frontends/frontend_theme_model';
import {arrayJoin} from '@shared/lib/array_utils';

import {HoobiizMediaView} from '@shared-frontend/components/auth/hoobiiz_media_view';
import {EmptyFragment} from '@shared-frontend/lib/react';

interface LoginTheme1Props {
  whiteLabeling: WhiteLabeling['login'] & {type: HoobiizWhiteLabelingLoginType.Theme1};
  children: ReactNode | ReactNode[];
}

export const LoginTheme1: FC<LoginTheme1Props> = props => {
  const {whiteLabeling} = props;
  const {hero, logo, title} = whiteLabeling;
  return (
    <Wrapper>
      <Left>
        {logo?.media ? (
          <LogoContainer>
            <HoobiizMediaView
              media={logo.media}
              cover={false}
              size={{width: '100%', height: '100%'}}
              objectPosition={'right'}
            />
          </LogoContainer>
        ) : (
          // <HoobiizMediaView media={logo.media} size={{width: '300px'}} />
          EmptyFragment
        )}
        {title !== undefined ? (
          <Title>
            {arrayJoin(
              title.split('\n').map(line => <span key={line}>{line}</span>),
              i => (
                <br key={i} />
              )
            )}
          </Title>
        ) : (
          EmptyFragment
        )}
        {/* {children} */}
      </Left>
      <Right>
        {hero?.media ? (
          <HoobiizMediaView
            media={hero.media}
            size={{width: '100%', height: '100%'}}
            cover
            objectPosition={'right'}
          />
        ) : (
          EmptyFragment
        )}
      </Right>
    </Wrapper>
  );
};

const COLUMN_LAYOUT_THRESHOLD = '850px';

LoginTheme1.displayName = 'LoginTheme1';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  display: flex;
  background-color: #ccc;
`;

const Left = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  background: linear-gradient(to right, #5cdfe5, #004cad);

  flex-shrink: 0;
  @media (max-width: ${COLUMN_LAYOUT_THRESHOLD}) {
    flex-shrink: unset;
    flex-grow: 1;
  }

  padding: 4vw;
  @media (max-width: ${COLUMN_LAYOUT_THRESHOLD}) {
    padding: 32px;
  }
`;

const Title = styled.div`
  font-size: 2vw;
  font-weight: bold;
  color: white;
  text-align: center;
  line-height: 1.5;

  font-size: 4vw;
  @media (max-width: ${COLUMN_LAYOUT_THRESHOLD}) {
    font-size: 6vw;
  }
  @media (min-width: 1100px) {
    font-size: 34px;
  }
`;

const Right = styled.div`
  flex-grow: 1;
  @media (max-width: ${COLUMN_LAYOUT_THRESHOLD}) {
    display: none;
  }
`;

const LogoContainer = styled.div`
  width: 25vw;
  @media (max-width: ${COLUMN_LAYOUT_THRESHOLD}) {
    width: 80vw;
  }
`;
